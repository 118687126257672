















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question496',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        positiveFormalCharge: [],
        language: 'fr',
      },
      options: [
        {text: 'Molécule A', value: 'A'},
        {text: 'Molécule B', value: 'B'},
        {text: 'Molécule C', value: 'C'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/formalChargeAssessment.png';
    },
  },
};
